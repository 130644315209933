<template>
  <div class="incubation-service">
    <div class="container">
      <div class="Header-item"><Header /></div>
      <div><Carousel /></div>
      <!-- 上市前孵化服务 -->
      <div class="incubation-content">
        <!-- 首页模式及商业计划书模块 -->
        <div class="content-box">
          <h3 class="content-title">{{ $t("lang.pattern.title") }}</h3>
          <div class="main">
            <div class="item">
              <div class="item-box index">
                <img @click="bpClick" src="../assets/home_service1_icon_1.png" alt="bp辅导" class="logo-img" />
                <div>
                  <h5 class="item-title" @click="bpClick">{{ $t("lang.pattern.pattrrm1") }}<i class="el-icon-arrow-right"></i></h5>
                  <p class="item-text">{{ $t("lang.pattern.pattrrm1center") }}</p>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="item-box index">
                <img src="../assets/home_service1_icon_2.png" alt="" class="logo-img" />
                <div>
                  <h5 class="item-title">{{ $t("lang.pattern.pattrrm2") }}</h5>
                  <p class="item-text">{{ $t("lang.pattern.pattrrm1center2") }}</p>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="item-box index">
                <img src="../assets/home_service1_icon_3.png" alt="" class="logo-img" />
                <div>
                  <h5 class="item-title">{{ $t("lang.pattern.pattrrm3") }}</h5>
                  <p class="item-text">{{ $t("lang.pattern.pattrrm1center3") }}</p>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="item-box index">
                <img src="../assets/home_service1_icon_4.png" alt="" class="logo-img" />
                <div>
                  <h5 class="item-title">{{ $t("lang.pattern.pattrrm4") }}</h5>
                  <p class="item-text">{{ $t("lang.pattern.pattrrm1center4") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 合规管理模块 -->
        <div class="content-box">
          <h3 class="content-title">{{ $t("lang.management.title") }}</h3>
          <div class="main manage">
            <div
              class="item-compliance"
            >
              <img src="../assets/compliance_img1.png" alt="" class="item-img" />
              <div class='item-compliance-text'>{{ $t("lang.management.text1") }}</div>
            </div>
            <div
              class="item-compliance"
            >
              <img src="../assets/compliance_img2.png" alt="" class="item-img" />
              <div class='item-compliance-text'>{{ $t("lang.management.text2") }}</div>
            </div>
            <div
              class="item-compliance"
            >
              <img src="../assets/compliance_img3.png" alt="" class="item-img" />
              <div class='item-compliance-text'>{{ $t("lang.management.text3") }}</div>
            </div>
            <div
              class="item-compliance"
            >
              <img src="../assets/compliance_img4.png" alt="" class="item-img" />
              <div class='item-compliance-text'>{{ $t("lang.management.text4") }}</div>
            </div>
            <div
              class="item-compliance"
            >
              <img src="../assets/compliance_img5.png" alt="" class="item-img" />
              <div class='item-compliance-text'>{{ $t("lang.management.text5") }}</div>
            </div>
            <div
              class="item-compliance"
            >
              <img src="../assets/compliance_img6.png" alt="" class="item-img" />
              <div class='item-compliance-text'>{{ $t("lang.management.text6") }}</div>
            </div>
            <div
              class="item-compliance"
            >
              <img src="../assets/compliance_img7.png" alt="" class="item-img" />
              <div class='item-compliance-text'>{{ $t("lang.management.text7") }}</div>
            </div>
          </div>
        </div>
        <!-- 品牌孵化模块 -->
        <div class="content-box">
          <h3 class="content-title">{{ $t("lang.brand.title") }}</h3>
          <div class="main brand">
            <div
              class="brand-incubation"
            >
              <img src="../assets/home_service1_icon_5.png" alt="" class="brand-img" />
              <span class="brand-title">{{ $t("lang.brand.text1") }}</span>
            </div>
            <div
              class="brand-incubation"
            >
              <img src="../assets/home_service1_icon_6.png" alt="" class="brand-img" />
              <span class="brand-title">{{ $t("lang.brand.text2") }}</span>
            </div>
            <div
              class="brand-incubation"
            >
              <img src="../assets/home_service1_icon_7.png" alt="" class="brand-img" />
              <span class="brand-title">{{ $t("lang.brand.text3") }}</span>
            </div>
            <div
              class="brand-incubation"
            >
              <img src="../assets/home_service1_icon_8.png" alt="" class="brand-img" />
              <span class="brand-title">{{ $t("lang.brand.text4") }}</span>
            </div>
            <div
              class="brand-incubation"
            >
              <img src="../assets/home_service1_icon_9.png" alt="" class="brand-img" />
              <span class="brand-title">{{ $t("lang.brand.text5") }}</span>
            </div>
          </div>
        </div>
        <!-- 企业注册资本实缴 -->
        <div class="content-box">
          <h3 class="content-title">{{$t('lang.incubation.title')}}</h3>
          <div class="capital-box">
            <div class="capital-list" v-for="(item,index) in capitalList" :key="index">
              <div class="capital-title h5_none">{{item.title}}</div>
              <div class="capital-info" :class="{'capital-on':item.isOpen,'capital-text-off':getLangIndex!=0 && !getIsPc}">
                <div class="capital-text">{{item.text}}</div>
                <div class="capital-btn" v-show="getLangIndex!=0 && !getIsPc"><img @click="btnClick(item)" :src="!item.isOpen?openImg.down:openImg.up"/></div>
                <div class="capital-img">
                  <img class="h5_none" :src="item.pcUrl" alt="" />
                  <img class="web_none" :src="item.h5Url" alt="" />
                  <div class="capital-title-h5 web_none">{{item.title}}</div>
                </div>
              </div>
            </div>
          </div>
          <h3 class="content-child-title">{{$t('lang.incubation.childTitle')}}</h3>
          <div class="capital-item">
            <div class="item-custom" v-for="(item,index) in $t('lang.incubation.capitalData')" :key="index">
              <img :src="item.url" alt="">
              <div class="custom-info-box">
                <div class="custom-title">{{item.title}}</div>
                <div class="custom-info">{{item.text}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 品牌合一模块 -->
        <div class="product-sale">
          <div
            class="product-item"
          >
            <p class="sale-title">{{ $t("lang.Brandandsales.TITLE") }}</p>
            <p class="text">{{ $t("lang.Brandandsales.center") }}</p>
          </div>
          <div
            class="product-item"
          >
            <p class="sale-title">{{ $t("lang.camp.TITLE") }}</p>
            <p class="text">{{ $t("lang.camp.center") }}</p>
          </div>
        </div>
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import Carousel from '@/components/Carousel/Carousel.vue'
import Copyright from '@/components/Copyright/Copyright.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Incubation',
  components: {
    Header,
    Carousel,
    Copyright
  },
  computed: {
    ...mapGetters(['getLangIndex', 'getIsPc'])
  },
  watch: {
    getLangIndex (old) {
      this.capitalList = this.$t('lang.incubation.capitalList')
    }
  },
  data () {
    return {
      openImg: {
        down: require('@/assets/incubation/icon_down.png'),
        up: require('@/assets/incubation/icon_up.png')
      },
      capitalList: this.$t('lang.incubation.capitalList')
    }
  },
  methods: {
    bpClick () {
      this.$router.push({ path: 'bpcoach' })
    },
    btnClick (item) {
      item.isOpen = !item.isOpen
    }
  },
  created () {
    this.$store.dispatch('setNavIndex', 2)
  }
}
</script>
<style scoped lang="less">
.incubation-content {
  background: #efefef;
  // 模块
  .content-box {
   /*  padding: 5vh 5vw 0 5vw; */
   padding-top: 5vw;
    width: 1200px;
    max-width: 1200px;
    margin: auto;
    // 模块标题
    .content-title {
      margin-bottom: 2vh;
      border-left: 5px solid #17cad0;
      padding-left: 1vw;
    }
    // 模块内容
    .main {
      display: flex;
      flex-wrap: wrap;
      padding: 2vh 0;
      .item:first-child{
        .item-box img{
          cursor: pointer;
        }
        .item-title{
          cursor: pointer;
          color: #22969A !important;
          i{
            font-weight: 600;
          }
        }
      }
      // 首页模式
      .item {
        width: 50%;
        display: flex;
        margin-bottom: 5vh;
        justify-content: center;
        .item-box {
          width: 90%;
          display: flex;
          .item-title {
            font-size: 18px;
            margin-bottom: 1vh;
          }
          .item-text {
            line-height: 25px;
            color: #999;
          }
          .logo-img {
            width: 120px;
            height: 120px;
            margin-right: 2vw;
          }
        }
      }
      // 合规管理
      .item-compliance {
        width: 25%;
        margin-bottom: 3vh;
        display: flex;
        justify-content: center;
        position: relative;
        .item-img {
          width: 80%;
        }
        .item-compliance-text{
          position: absolute;
          z-index: 1;
          font-size: 16px;
          font-weight: 600;
          color: #FFFFFF;
          display: flex;
          width: 80%;
          justify-content: center;
          align-items: center;
          height: 100%;
          text-align: center;
        }
      }
      // 股权投资
      .equity-inverstment {
        width: 20%;
        .equity-text {
          width: 60%;
          margin: 2vh 0;
          padding-left: 4vw;
        }
        .equity-img {
          width: 50%;
          padding-left: 4vw;
        }
      }
      // 品牌孵化
      .brand-incubation {
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 5vh;
        .brand-title {
          font-size: 18px;
          font-weight: bold;
        }
        .brand-img {
          width: 120px;
          height: 120px;
        }
      }
    }
    .manage {
      justify-content: center;
    }
    .capital-box{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .web_none{
        display: none;
      }
      .capital-list{
        padding: 20px 0 40px;
        max-width: 47%;
        .capital-title{
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 15px;
        }
        .capital-info{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .capital-btn{
            text-align: center;
            padding: 0.5rem 0 1rem;
            img{
              width: 1.5rem;
              height: 1.5rem;
            }
          }
          &.capital-text-off{
            .capital-text{
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 6;
              -webkit-box-orient: vertical;
              margin: 1rem 0 0 !important;
            }
          }
          &.capital-on{
            .capital-text{
              margin: 1rem 0 0 !important;
              overflow: visible;
              display:block;
              -webkit-line-clamp: none;
              -webkit-box-orient: none;
            }
          }
          .capital-text{
            font-size: 13px;
            color: #999;
            margin-right: 30px;
            position: relative;
          }
          .capital-img{
            position: relative;
            img{
              max-width: 252px;
            }
            .capital-title-h5{
              position: absolute;
              left: 0;
              top:0;
              right: 0;
              bottom: 0;
              justify-content: center;
              align-items: center;
              color: #fff;
              font-size: 1.31rem;
              font-weight: 600;
            }
          }
        }
      }
    }
    .content-child-title{
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 15px;
    }
    .capital-item{
      display: flex;
      padding: 30px 0 100px;
      .item-custom{
        width: 25%;
        position: relative;
        img{
          position: absolute;
          top: 0;
          left: 0;
          // height: 120px;
          width: 120px;
        }
        .custom-info-box{
          padding: 40px 20px 0 48px;
          z-index: 10;
          .custom-title{
            text-indent: 1rem;
            font-size: 24px;
            font-weight: 600;
            padding-bottom: 10px;
          }
          .custom-info{
            font-size: 14px;
            color: #888;
          }
        }
      }
    }
  }
  // 品牌合一
  .product-sale {
    padding: 5vh 5vw;
    display: flex;
    justify-content: space-between;
    background: white;
    .product-item {
      width: 45%;
      .sale-title {
        font-weight: bold;
        margin-bottom: 1vh;
      }
      .text {
        font-size: 13px;
        color: #999;
      }
    }
  }
}
.container{
  .Header-item{
    position: absolute;
    z-index: 10;
    width: 100%;
  }
}
</style>
